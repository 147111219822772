/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

import { aliases, mdi } from "vuetify/lib/iconsets/mdi.mjs";

// Composables
import { createVuetify } from "vuetify";

// Wedding Green theme
const weddingGreenTheme = {
  dark: true,
  colors: {
    primary: "#243d23", // Our Wedding Green
    background: "#fffff0", // Light background (Ivory)
    // background: "#243d23", // Dark background
    surface: "#242424", // Slightly lighter surface color
    "primary-darken-1": "#8B1A1A", // Darkened Red
    secondary: "#145A32", // Dark Christmas Green
    "secondary-darken-1": "#0F3D2E", // Darkened Green
    error: "#992D22", // Darker Red for errors
    info: "#2E86C1", // Icy Blue for informational messages
    success: "#1E8449", // Vibrant Green for success
    warning: "#F39C12", // Muted Orange for warnings
  },
};

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    // components,
    // directives,
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: { mdi },
    },
    theme: {
      defaultTheme: "weddingGreenTheme",
      themes: {
        weddingGreenTheme,
      },
    },
  });
  app.vueApp.use(vuetify);
});
