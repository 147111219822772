import { default as confirmationmeqWb8fiy3Meta } from "/home/runner/work/le-wedding/le-wedding/pages/confirmation.vue?macro=true";
import { default as index8Q87AtWd2hMeta } from "/home/runner/work/le-wedding/le-wedding/pages/index.vue?macro=true";
import { default as infoD8OrOg6F3aMeta } from "/home/runner/work/le-wedding/le-wedding/pages/info.vue?macro=true";
import { default as listeZKmGdmaG3TMeta } from "/home/runner/work/le-wedding/le-wedding/pages/liste.vue?macro=true";
import { default as merciROoZCRklpEMeta } from "/home/runner/work/le-wedding/le-wedding/pages/merci.vue?macro=true";
import { default as photosDlCSNn1CqaMeta } from "/home/runner/work/le-wedding/le-wedding/pages/photos.vue?macro=true";
import { default as save_45the_45date7j2X6droaNMeta } from "/home/runner/work/le-wedding/le-wedding/pages/save-the-date.vue?macro=true";
export default [
  {
    name: "confirmation",
    path: "/confirmation",
    component: () => import("/home/runner/work/le-wedding/le-wedding/pages/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/le-wedding/le-wedding/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/runner/work/le-wedding/le-wedding/pages/info.vue").then(m => m.default || m)
  },
  {
    name: "liste",
    path: "/liste",
    component: () => import("/home/runner/work/le-wedding/le-wedding/pages/liste.vue").then(m => m.default || m)
  },
  {
    name: "merci",
    path: "/merci",
    component: () => import("/home/runner/work/le-wedding/le-wedding/pages/merci.vue").then(m => m.default || m)
  },
  {
    name: "photos",
    path: "/photos",
    component: () => import("/home/runner/work/le-wedding/le-wedding/pages/photos.vue").then(m => m.default || m)
  },
  {
    name: "save-the-date",
    path: "/save-the-date",
    component: () => import("/home/runner/work/le-wedding/le-wedding/pages/save-the-date.vue").then(m => m.default || m)
  }
]