import payload_plugin_ALLXFGgbCP from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/le-wedding/le-wedding/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_pNVVgm7Ud2 from "/home/runner/work/le-wedding/le-wedding/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_plugin_8cq4FnzXKb from "/home/runner/work/le-wedding/le-wedding/.nuxt/vuefire-plugin.mjs";
import analytics_client_mtqVw3YRXk from "/home/runner/work/le-wedding/le-wedding/plugins/analytics.client.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/le-wedding/le-wedding/plugins/sentry.client.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/le-wedding/le-wedding/plugins/vuetify.ts";
export default [
  payload_plugin_ALLXFGgbCP,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_pNVVgm7Ud2,
  vuefire_plugin_8cq4FnzXKb,
  analytics_client_mtqVw3YRXk,
  sentry_client_shVUlIjFLk,
  vuetify_7h9QAQEssH
]