<template>
  <NuxtLayout>
    <v-app>
      <v-app-bar color="primary">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title align="center">
          <router-link to="/">
            <v-img
              class="mx-2 whitefy"
              src="/images/logo.png"
              height="64px"
              alt="Le Wedding des Chatons"
            ></v-img>
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon class="invisible"></v-app-bar-nav-icon>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        location="bottom"
        color="primary"
        temporary
        floating
      >
        <v-list>
          <v-list-item
            v-for="(item, i) in items"
            :to="item.to"
            :key="i"
            :title="item.title"
          ></v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <NuxtPage />
      </v-main>

      <v-footer color="primary">
        <v-container align="center">
          <v-row>
            <v-col xs12 cols="6" class="d-flex justify-end">
              <img height="200px" src="/images/logo.png" class="whitefy" />
            </v-col>
            <v-col cols="6" class="d-flex flex-column justify-start">
              <v-btn
                :href="link.to"
                v-for="link in items"
                :key="link.to"
                :text="link.title"
                style="justify-content: flex-start"
                variant="text"
                >{{ link.title }}
              </v-btn>
            </v-col>
            <v-col cols="12">&copy; 2024 Marion & Charles-Antoine</v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-app>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import * as Sentry from '@sentry/vue';
const drawer = ref(false);
const items = [
  {
    to: '/',
    title: 'Accueil',
  },
  {
    to: 'confirmation',
    title: 'Confirmation',
  },
  {
    to: 'liste',
    title: 'Liste de mariage',
  },
  {
    to: 'info',
    title: 'Informations Pratiques',
  },
  // {
  //   to: 'photos',
  //   title: 'Photos ✨',
  // },
];

const registerSentryUser = () => {
  const id = localStorage.getItem('contributorPhoneNumber') || '';
  const email = localStorage.getItem('contributorEmail') || '';
  const username = localStorage.getItem('contributorName') || '';
  if (username || email || id) {
    Sentry.setUser({
      ...(id ? { id } : {}),
      ...(email ? { email } : {}),
      ...(username ? { username } : {}),
      ip_address: '{{auto}}',
    });
  }
};

if (process.client) {
  registerSentryUser();
}
</script>

<style>
html {
  font-family: 'Lato';
}
h1,
h2 {
  font-family: 'Prata';
  color: #243d23;
}
.v-card-title {
  font-family: 'Prata';
}
.whitefy {
  filter: brightness(100);
}
.invisible {
  pointer-events: none;
  opacity: 0;
}
p {
  > a:link {
    color: #243d23;
  }
  > a:visited {
    color: #b76d68;
  }
  > a:hover {
    color: #547aa5;
  }
  > a:active {
    color: #440d0f;
  }
}
</style>
